import React from "react";
import styles from "./Header.module.scss";
import { useLocation } from "react-router-dom";

export function Header(props) {
  const location = useLocation();
  const isFrontOrEdu =
    location.pathname == "/" || location.pathname == "/nyISkole";
  return (
    <div className={isFrontOrEdu ? styles.Header : styles.Header2}>
      <img
        fetchpriority="high"
        className={styles.headerImage}
        src={props.imageSrc}
        alt="Børn på FaarvejleFriskole klatre i træ."
      />
      <h1 className={styles.headerText}>{props.title}</h1>
      <div className={styles.headerTextBox}>
        <h3>{props.subtitle}</h3>
        <p>{props.text}</p>
        <span dangerouslySetInnerHTML={{ __html: props.htmlElement }} />
        {props.children}
      </div>
    </div>
  );
}
