import React, { useState, useEffect } from "react";
import styles from "./Footer.module.scss";
import SoMeIcons from "./SoMeIcons";
import axios from "axios";

function Footer() {
  const baseUrl = "https://be.faarevejlefriskole.dk";

  const [content, setContent] = useState({});

  useEffect(() => {
    async function getContent() {
      const config = {
        headers: {
          Authorization:
            "bearer f0147ceefc4d6e6ffbaad421d557c0298c72964da5b8a68e34bd110b99441c18f2a52498e6c7393b98d1c00f77050f85ae8a08c67ef387ddbf63024f58c305cb108eeb85c39cc826d17c41cc9564ba69e3fa48f1309f0ce7e6d171c2456adc290763a14ccd2dee7cf3ebe3dba05f01788e998c896585cff0f0a7e0eb93bd9cd5",
        },
      };

      await axios
        .get(`${baseUrl}/api/footer?populate=*`, config)
        .then(({ data }) => setContent(data.data));
    }

    if (Object.keys(content).length < 1) {
      getContent();
    }
  }, [content]);

  if (Object.keys(content).length < 1) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.Footer}>
      <div className={styles.section1}>
        <div className={styles.logo}>
          <h1 className={styles.logoText}>Faarevejle</h1>
          <h1 className={styles.logoText}>Friskole</h1>
        </div>
        <SoMeIcons />
        <div className={styles.linkButtons}>
          <button className={styles.infoLink}>
            <a href="foraeldre#ministerielt">Ministerielt</a>
          </button>
          <button className={styles.infoLink}>
            <a href="cookies">Cookies</a>
          </button>
          <button className={styles.infoLink}>
            <a href="privatlivspolitik">Privatlivspolitik</a>
          </button>
        </div>
      </div>

      <div className={styles.section2}>
        <div className={styles.kontakt}>
          <h5 className={styles.kontaktTitle}>Kontakt</h5>
          <div className={styles.kontaktInfo}>
            <p className={styles.kontaktText}>
              {" "}
              <a href={`tel:+45${content.attributes.bentKontaktInfo.telefon}`}>
                Tlf: {content.attributes.bentKontaktInfo.telefon}
              </a>
            </p>
            <p className={styles.kontaktText}>
              <a href={`mailto:${content.attributes.bentKontaktInfo.mail}`}>
                {content.attributes.bentKontaktInfo.mail}
              </a>
            </p>
            <p className={styles.kontaktText}>
              Konto: {content.attributes.bentKontaktInfo.konto}
            </p>
          </div>
        </div>
        <div className={styles.kontorInfo}>
          <p className={styles.kontorInfoText}>
            <a href="https://www.google.com/maps/place/Fårevejle+Fri-+og+Efterskole/@55.7889676,11.4592169,21z/data=!4m5!3m4!1s0x464d8426e368f4b9:0xc5fca1892fc131fc!8m2!3d55.7883912!4d11.4636402">
              Faarevejle Friskole
              <br />
              Skjoldsvej 5<br />
              4540 Fårevejle
            </a>
          </p>
          <p className={styles.kontorInfoText}>
            {content.attributes.kontorAabningstider}
          </p>
        </div>
        <p className={styles.LedigeStillinger}>
          Se ledige stillinger på vores{" "}
          <a href="https://www.facebook.com/faarevejlefriskole.dk">
            Facebook side
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
