import { Header } from "../../Header";
import SoMeIcons from "../../SoMeIcons";
import styles from "./Forside.module.scss";
import Faciliteter from "../../Faciliteter";
import InfoBox from "../../InfoBox";
import { useEffect, useState } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

export function Forside() {
  const baseUrl = "https://be.faarevejlefriskole.dk";

  const [content, setContent] = useState({});

  useEffect(() => {
    async function getContent() {
      const config = {
        headers: {
          Authorization:
            "bearer f0147ceefc4d6e6ffbaad421d557c0298c72964da5b8a68e34bd110b99441c18f2a52498e6c7393b98d1c00f77050f85ae8a08c67ef387ddbf63024f58c305cb108eeb85c39cc826d17c41cc9564ba69e3fa48f1309f0ce7e6d171c2456adc290763a14ccd2dee7cf3ebe3dba05f01788e998c896585cff0f0a7e0eb93bd9cd5",
        },
      };

      await axios
        .get(
          `${baseUrl}/api/homepage?populate=historieStortBillede&populate=historieLilleBillede&populate=skoleVideo&populate=forside_infoboxes&populate=infoBox.billede&populate=headerBillede&populate=header.billede&populate=historie.stortBillede&populate=historie.lilleBillede`,
          config
        )
        .then(({ data }) => setContent(data.data));
    }

    if (Object.keys(content).length < 1) {
      getContent();
    }
  }, [content]);

  if (Object.keys(content).length < 1) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.Forside}>
      <Header
        title={content.attributes.header.title}
        subtitle={content.attributes.header.subTitle}
        text={content.attributes.header.text}
        imageSrc={
          baseUrl + content.attributes.header.billede.data.attributes.url
        }
      />

      <div className={styles.historie}>
        <img
          className={styles.kastanjenBillede}
          src={
            baseUrl +
            content.attributes.historie.stortBillede.data.attributes.url
          }
          alt={
            content.attributes.historie.stortBillede.data.attributes
              .alternativeText
          }
        ></img>
        <div className={styles.historieInfo}>
          <h3>{content.attributes.historie.header}</h3>
          <p>{content.attributes.historie.text}</p>
          <img
            src={
              baseUrl +
              content.attributes.historie.lilleBillede.data.attributes.url
            }
            alt={
              content.attributes.historie.lilleBillede.data.attributes
                .alternativeText
            }
          />
        </div>
      </div>

      <Faciliteter />

      <div className={styles.videoWrap}>
        <video className={styles.video} controls>
          <source
            src={baseUrl + content.attributes.skoleVideo.data.attributes.url}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      {content.attributes.infoBox.map((infoBoxElement) => (
        <InfoBox
          element={infoBoxElement}
          key={`infoBoxElement${infoBoxElement.id}`}
        />
      ))}

      <div className={styles.soMe}>
        <h3>Følg med på Sociale medier</h3>
        <SoMeIcons />
      </div>

      <MapContainer
        center={[55.78860251823712, 11.461555204393195]}
        zoom="16"
        scrollWheelZoom={false}
        style={{ height: "400px", width: "80%", margin: "0 auto", zIndex: "1" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[55.78860580735302, 11.461555204393195]}>
          <Popup>Skjoldsvej 5, 4540 Fårevejle</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}
