import { BsFacebook, BsYoutube } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import styles from "./SoMeIcons.module.scss";
import { useState, useEffect } from "react";
import axios from "axios";

function SoMeIcons() {
  const baseUrl = "https://be.faarevejlefriskole.dk";

  const [content, setContent] = useState({});

  useEffect(() => {
    async function getContent() {
      const config = {
        headers: {
          Authorization:
            "bearer f0147ceefc4d6e6ffbaad421d557c0298c72964da5b8a68e34bd110b99441c18f2a52498e6c7393b98d1c00f77050f85ae8a08c67ef387ddbf63024f58c305cb108eeb85c39cc826d17c41cc9564ba69e3fa48f1309f0ce7e6d171c2456adc290763a14ccd2dee7cf3ebe3dba05f01788e998c896585cff0f0a7e0eb93bd9cd5",
        },
      };

      await axios
        .get(`${baseUrl}/api/some`, config)
        .then(({ data }) => setContent(data.data));
    }

    if (Object.keys(content).length < 1) {
      getContent();
    }
  }, [content]);

  if (Object.keys(content).length < 1) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.SoMeIconRow}>
      <a href={content.attributes.facebook}>
        <BsFacebook className={styles.SoMeIcon} />
      </a>
      <a href={content.attributes.instagram}>
        <RiInstagramFill className={styles.SoMeIcon} />
      </a>
      <a href={content.attributes.youtube}>
        <BsYoutube className={styles.SoMeIcon} />
      </a>
    </div>
  );
}

export default SoMeIcons;
