import React, { useState } from "react";
import styles from "./FaciliteterImage.module.scss";

function FaciliteterImage(props) {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div
      className={
        isHover ? styles.FaciliteterImageHover : styles.FaciliteterImage
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={props.imageSrc} alt={props.title} />
      <div className={styles.overlay}>
        <h1 className={styles.overlayHeader1}>{props.title}</h1>
        <h4 className={styles.overlayHeader2}>{props.text}</h4>
        <h4 className={styles.overlayHeader2}>{props.author}</h4>
      </div>
    </div>
  );
}

export default FaciliteterImage;
