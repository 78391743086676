import styles from "./NavBar.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

export const NavBar = () => {
  const [navbar, setNavbar] = useState(false);

  const [click, setClick] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 60) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    }
    window.addEventListener("scroll", handleScroll);

    return function cleanUpListener() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleClick = () => {
    window.scrollTo(0, 0);
    setClick(false);
  };

  const toggleMenu = () => {
    setClick(!click);
  };

  const location = useLocation();
  return (
    <div className={styles.navBarContainerShown}>
      <div className={styles.navBarLogoContainer}>
        <h1 className={styles.navBarLogoTitle}>Faarevejle</h1>
        <h1 className={styles.navBarLogoTitle}>Friskole</h1>
      </div>
      <div
        className={
          click ? styles.navBarLinkContainerShown : styles.navBarLinkContainer
        }
      >
        <div className={styles.navBarLinkContainerItemContainer}>
          <NavLink
            exact="true"
            index="true"
            to="/"
            className={({ isActive }) =>
              isActive && location.pathname.length === 1
                ? styles.navBarLinkContainerItemActive
                : styles.navBarLinkContainerItem
            }
            onClick={handleClick}
          >
            Forside
          </NavLink>
        </div>
        <div className={styles.navBarLinkContainerItemContainer}>
          <NavLink
            exact="true"
            to="/nyISkole"
            className={({ isActive }) =>
              isActive
                ? styles.navBarLinkContainerItemActive
                : styles.navBarLinkContainerItem
            }
            onClick={handleClick}
          >
            Ny i skole
          </NavLink>
        </div>
        <div className={styles.navBarLinkContainerItemContainer}>
          <NavLink
            exact="true"
            to="/undervisning"
            className={({ isActive }) =>
              isActive
                ? styles.navBarLinkContainerItemActive
                : styles.navBarLinkContainerItem
            }
            onClick={handleClick}
          >
            Undervisning
          </NavLink>
        </div>
        <div className={styles.navBarLinkContainerItemContainer}>
          <NavLink
            exact="true"
            to="/foraeldre"
            className={({ isActive }) =>
              isActive
                ? styles.navBarLinkContainerItemActive
                : styles.navBarLinkContainerItem
            }
            onClick={handleClick}
          >
            Forældre
          </NavLink>
        </div>
      </div>
      <div className={styles.navIcon} onClick={toggleMenu}>
        <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
      </div>
    </div>
  );
};
