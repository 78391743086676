import React from 'react'
import styles from "./BlivElevKnap.module.scss";
import { useNavigate } from "react-router-dom";

export default function BlivElevKnap() {

  const navigate = useNavigate()

  return (
    <button className={styles.BlivElevKnap} onClick={() => navigate("/BlivElev")}>Bliv elev</button>
  )
}
