import styles from "./NyISkole.module.scss";
import InfoBox from "../../InfoBox";
import { Header } from "../../Header";
import NyElev from "../../NyElev";
import { useState, useEffect } from "react";
import axios from "axios";

export function NyISkole() {
  const baseUrl = "https://be.faarevejlefriskole.dk";

  const [content, setContent] = useState({});

  useEffect(() => {
    async function getContent() {
      const config = {
        headers: {
          Authorization:
            "bearer f0147ceefc4d6e6ffbaad421d557c0298c72964da5b8a68e34bd110b99441c18f2a52498e6c7393b98d1c00f77050f85ae8a08c67ef387ddbf63024f58c305cb108eeb85c39cc826d17c41cc9564ba69e3fa48f1309f0ce7e6d171c2456adc290763a14ccd2dee7cf3ebe3dba05f01788e998c896585cff0f0a7e0eb93bd9cd5",
        },
      };

      await axios
        .get(
          `${baseUrl}/api/ny-i-skole?populate=infoBox.billede&populate=sfoKomponent.billede&populate=sfoKomponent.vaegte&populate=header.billede`,
          config
        )
        .then(({ data }) => setContent(data.data));
    }

    if (Object.keys(content).length < 1) {
      getContent();
    }
  }, [content]);

  if (Object.keys(content).length < 1) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.NyISkole}>
      <Header
        title={content.attributes.header.title}
        subtitle={content.attributes.header.subTitle}
        text={content.attributes.header.text}
        imageSrc={
          baseUrl + content.attributes.header.billede.data.attributes.url
        }
      />

      <InfoBox element={content.attributes.infoBox} />

      <div className={styles.SFO}>
        <div className={styles.SFOText}>
          <div>
            <h4>{content.attributes.sfoKomponent.title}</h4>
            <p>{content.attributes.sfoKomponent.titleSubText}</p>
            <h4>{content.attributes.sfoKomponent.subTitle}</h4>
            <ul>
              {content.attributes.sfoKomponent.vaegte.map((element) => (
                <li key={`element${element.id}`}>{element.text}</li>
              ))}
            </ul>
          </div>
          <img
            src={
              baseUrl +
              content.attributes.sfoKomponent.billede.data.attributes.url
            }
            alt={
              content.attributes.sfoKomponent.billede.data.attributes
                .alternativeText
            }
          />
        </div>
        <p className={styles.endText}>
          {content.attributes.sfoKomponent.endText}
        </p>
      </div>

      <NyElev />
    </div>
  );
}
