import styles from "./Foraeldre.module.scss";
import { Header } from "../../Header";
import SoMeIcons from "../../SoMeIcons";
import { useState, useEffect } from "react";
import axios from "axios";

export function Foraeldre() {
  const baseUrl = "https://be.faarevejlefriskole.dk";

  const [content, setContent] = useState({});

  useEffect(() => {
    async function getContent() {
      const config = {
        headers: {
          Authorization:
            "bearer f0147ceefc4d6e6ffbaad421d557c0298c72964da5b8a68e34bd110b99441c18f2a52498e6c7393b98d1c00f77050f85ae8a08c67ef387ddbf63024f58c305cb108eeb85c39cc826d17c41cc9564ba69e3fa48f1309f0ce7e6d171c2456adc290763a14ccd2dee7cf3ebe3dba05f01788e998c896585cff0f0a7e0eb93bd9cd5",
        },
      };

      await axios
        .get(
          `${baseUrl}/api/foraeldre?populate=oekonomiBox&populate=infoCard.infoCardText&populate=moderation.subText&populate=aarsKalender.billede&populate=aarsKalender.begivenhed&populate=sygemelding&populate=ministerielt.fil.file&populate=header.billede&populate=ministerielt.links`,
          config
        )
        .then(({ data }) => setContent(data.data));
    }

    if (Object.keys(content).length < 1) {
      getContent();
    }
  }, [content]);

  if (Object.keys(content).length < 1) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.Foraeldre}>
      <Header
        title={content.attributes.header.title}
        subtitle={content.attributes.header.subTitle}
        imageSrc={
          baseUrl + content.attributes.header.billede.data.attributes.url
        }
      >
        <div className={styles.headerButtons}>
          <SoMeIcons />
          <button
            href="https://ffe.viggo.dk/Basic/Account/Login"
            className={styles.button}
          >
            <h3>Forældre intra viggo</h3>
          </button>
        </div>
      </Header>
      <div className={styles.pageContent}>
        <div className={styles.box}>
          <h3>{content.attributes.oekonomiBox.title}</h3>
          <h4>{content.attributes.oekonomiBox.subTitle}</h4>
          <p>{content.attributes.oekonomiBox.text}</p>
          <div className={styles.cards}>
            {content.attributes.infoCard.map((element) => (
              <div
                className={styles.infoCard}
                key={`infoCardElement${element.id}`}
              >
                <h4>{element.title}</h4>
                {element.infoCardText.map((subElement) => (
                  <p key={`infoCardText${subElement.id}`}>{subElement.text}</p>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.moderation}>
            <h5>{content.attributes.moderation.title}</h5>
            {content.attributes.moderation.subText.map((element) => (
              <p key={`moderationElement${element.id}`}>{element.text}</p>
            ))}
          </div>
          <p>{content.attributes.moderation.endText}</p>
        </div>
        <div className={styles.calender}>
          <div className={styles.box}>
            <h3>{content.attributes.aarsKalender.title}</h3>
            {content.attributes.aarsKalender.begivenhed.map((element) => (
              <p key={`aarsKalenderElement${element.id}`}>{element.text}</p>
            ))}
          </div>
          <img
            src={
              baseUrl +
              content.attributes.aarsKalender.billede.data.attributes.url
            }
            alt={
              content.attributes.aarsKalender.billede.data.attributes
                .alternativeText
            }
          />
        </div>
        <div className={styles.box}>
          <h3>{content.attributes.sygemelding.title}</h3>
          <p>{content.attributes.sygemelding.text}</p>
        </div>
        <div className={styles.box} id="ministerielt">
          <h3>{content.attributes.ministerielt.title}</h3>
          {content.attributes.ministerielt.fil.map((element) => (
            <div>
              <a
                href={baseUrl + element.file.data[0].attributes.url}
                key={`ministerielt${element.id}`}
              >
                {element.text}
              </a>
            </div>
          ))}
          <div>
            <h3>Links:</h3>
            {content.attributes.ministerielt.links.map((element) => (
              <div>
                <a href={element.url} key={`ministerielt${element.id}`}>
                  {element.text}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
