import React from "react";
import styles from "./InfoBox.module.scss";

function InfoBox(props) {
  const baseUrl = "https://be.faarevejlefriskole.dk";

  if (props.element.htmlElement !== "") {
    return (
      <div
        className={`${styles.InfoBox} ${
          props.element.omvendt ? true : styles.reverse
        }`}
      >
        <img
          className={styles.image}
          src={baseUrl + props.element.billede.data.attributes.url}
          alt={props.element.billede.data.attributes.url}
        />

        <div className={styles.info}>
          <h3>{props.element.title}</h3>
          <p className={styles.text}>{props.element.text}</p>
          <span
            dangerouslySetInnerHTML={{ __html: props.element.htmlElement }}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${styles.InfoBox} ${
        props.element.omvendt ? true : styles.reverse
      }`}
    >
      <img
        className={styles.image}
        src={baseUrl + props.element.billede.data.attributes.url}
        alt={props.element.billede.data.attributes.url}
      />

      <div className={styles.info}>
        <h3>{props.element.title}</h3>
        <p className={styles.text}>{props.element.text}</p>
      </div>
    </div>
  );
}

export default InfoBox;
