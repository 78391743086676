import React, { useEffect, useState } from "react";
import styles from "./Faciliteter.module.scss";
import FaciliteterImage from "./FaciliteterImage";
import axios from "axios";

function Faciliteter() {
  const baseUrl = "https://be.faarevejlefriskole.dk";

  const [content, setContent] = useState({});

  useEffect(() => {
    async function getContent() {
      const config = {
        headers: {
          Authorization:
            "bearer f0147ceefc4d6e6ffbaad421d557c0298c72964da5b8a68e34bd110b99441c18f2a52498e6c7393b98d1c00f77050f85ae8a08c67ef387ddbf63024f58c305cb108eeb85c39cc826d17c41cc9564ba69e3fa48f1309f0ce7e6d171c2456adc290763a14ccd2dee7cf3ebe3dba05f01788e998c896585cff0f0a7e0eb93bd9cd5",
        },
      };

      await axios
        .get(
          `${baseUrl}/api/facilitet?populate=vaerdAtVide.file&populate=facilitetImage.billede`,
          config
        )
        .then(({ data }) => setContent(data.data));
    }

    if (Object.keys(content).length < 1) {
      getContent();
    }
  }, [content]);

  if (Object.keys(content).length < 1) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.Faciliteter}>
      <div className={styles.faciliteterText}>
        <h4>{content.attributes.title}</h4>
        <p className={styles.text}>
          {content.attributes.text}
          <a
            href={
              baseUrl +
              content.attributes.vaerdAtVide.file.data[0].attributes.url
            }
          >
            “{content.attributes.vaerdAtVide.text}”
          </a>
        </p>
      </div>
      <div className={styles.faciliteterImages}>
        {content.attributes.facilitetImage.map((element) => (
          <FaciliteterImage
            title={element.title}
            text={element.text}
            author={element.author}
            imageSrc={baseUrl + element.billede.data[0].attributes.url}
            key={`facilitet${element.id}`}
          />
        ))}
      </div>
    </div>
  );
}

export default Faciliteter;
