import { Header } from "../../Header";
import styles from "./Undervisning.module.scss";
import InfoBox from "../../InfoBox";
import { useState, useEffect } from "react";
import axios from "axios";

export function Undervisning() {
  const baseUrl = "https://be.faarevejlefriskole.dk";

  const [content, setContent] = useState({});

  useEffect(() => {
    async function getContent() {
      const config = {
        headers: {
          Authorization:
            "bearer f0147ceefc4d6e6ffbaad421d557c0298c72964da5b8a68e34bd110b99441c18f2a52498e6c7393b98d1c00f77050f85ae8a08c67ef387ddbf63024f58c305cb108eeb85c39cc826d17c41cc9564ba69e3fa48f1309f0ce7e6d171c2456adc290763a14ccd2dee7cf3ebe3dba05f01788e998c896585cff0f0a7e0eb93bd9cd5",
        },
      };

      await axios
        .get(
          `${baseUrl}/api/undervisning?populate=header.billede&populate=infoBox.billede`,
          config
        )
        .then(({ data }) => setContent(data.data));
    }

    if (Object.keys(content).length < 1) {
      getContent();
    }
  }, [content]);

  if (Object.keys(content).length < 1) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.Undervisning}>
      <Header
        title={content.attributes.header.title}
        subtitle={content.attributes.header.subTitle}
        text={content.attributes.header.text}
        htmlElement={content.attributes.header.htmlElement}
        imageSrc={
          baseUrl + content.attributes.header.billede.data.attributes.url
        }
      />

      <div className={styles.content}>
        {content.attributes.infoBox.map((infoBoxElement) => (
          <InfoBox
            element={infoBoxElement}
            key={`infoBoxElement${infoBoxElement.id}`}
          />
        ))}
      </div>
    </div>
  );
}
