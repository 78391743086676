import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { Optagelse } from "./components/pages/Optagelse/Optagelse";
import { Forside } from "./components/pages/Forside/Forside";
import { Undervisning } from "./components/pages/Undervisning/Undervisning";
import { NyISkole } from "./components/pages/NyISkole/NyISkole";
import { Foraeldre } from "./components/pages/Foraeldre/Foraeldre";
import BlivElevKnap from "./components/BlivElevKnap";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Forside />} />
          <Route path="/NyISkole" element={<NyISkole />} />
          <Route path="/Undervisning" element={<Undervisning />} />
          <Route path="/Foraeldre" element={<Foraeldre />} />
          <Route path="/BlivElev" element={<Optagelse />} />
        </Routes>
        <BlivElevKnap />
        <Footer />
      </Router>
    </>
  );
}

export default App;
